:root {
  --table_rating_bg-theme-color: var(--theme-color-4);
  --table_primary_title-theme-color: var(--theme-color-1);
  --table_exclusive_color-theme-color: #9B23F9;
}

$xs: 366px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$breakpoints: (
        "xs": $xs,
        "sm" : $sm,
        "md" : $md,
        "lg" : $lg,
        "xl" : $xl,
        "xxl" : $xxl
);
:export {
  xs: $xs;
  sm: $sm;
  md: $md;
  lg: $lg;
  xl: $xl;
  xxl: $xxl;
}

@function bp($bpName) {
  @return map-get($breakpoints, $bpName)
}


@import '../Vars.module';
@import '../Mixins';

:root {
  --math: 1;
}

[dir="rtl"] {
  --math: -1;
}

.r-btn {
  background: var(--theme-gradient-1);
  text-transform: uppercase;
  text-align: center;
  color: white;
  box-shadow: 0 1px 3px 0 #0000004D;
  font-weight: 700;
  text-decoration: none;
  border-radius: 50px;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  line-height: 45px;
  height: 45px;
  max-width: 184px;
  width: 100%;
  font-size: 1.6rem;
  text-shadow: 0px 1px 4px #00000040;
  &:hover {
    color: white;
    background: var(--theme-gradient-1-hover);
    box-shadow: 0px 1px 3px 0px #0000004D;
  }
  &-larger {
    height: 50px;
    font-size: 1.6rem;
    @media (min-width: bp(lg)) {
      font-size: 2rem;
    }
  }
  &-empty {
    background: none;
    color: var(--theme-color-1);
    border: 1px solid var(--theme-color-1);
    box-shadow: none;
    text-shadow: none;
    &:hover {
      background: none;
      color: var(--theme-color-1);
    }
  }
}

.prefix-btn:active {
  color: white;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

.exclusive {
  color: white;
  border-radius: 100px;
  font-size: 0.8rem;
  padding: 1px 10px;
  z-index: 2;
  text-transform: uppercase;
  text-align: center;
  inset-inline-start: 50%;
  bottom: 14px;
  transform: translateX(calc(var(--math) * -50%));
  background-color: var(--theme-color-6);
  @media (min-width: bp(md)) {
    bottom: 12px;
  }
  @media (min-width: bp(lg)) {
    transform: unset;
    inset-inline-end: -30px;
    inset-inline-start: unset;
    font-size: 1rem;
    top: 10px;
    bottom: unset;
  }
  &:not(.in-card) {
    position: absolute;
  }
}






